import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getStreams } from 'services/stream'
import Video, { StreamType } from 'components/Video/Video'
import logo from 'assets/sport360.png'
import styles from './Sports360.module.scss'

const Sports360 = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [streamId, setStreamId] = useState(params?.get('streamId') ?? null)
  const sourceId = params?.get('sourceId')
  const clientName = params?.get('clientName') ?? 'sportbuff'
  const anonymousLogin = params?.get('anonymousLogin') ?? true
  const widgetInstance = useRef<any>()
  const sportBuffContainer = useRef<HTMLElement>()
  const container = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoUrl = `https://buffup-public.prod.buffup.net/video/${
    params?.get('videoId') ?? 'FIFA_VOD_SHORT'
  }.${params?.get('videoExt') ?? 'mp4'}`
  const streamType =
    params?.get('isVOD') === 'true' ? StreamType.VOD : StreamType.LIVE

  const handleTimeUpdate = () => {
    widgetInstance?.current?.controls?.updateTimeVOD(
      videoRef?.current?.currentTime || 0
    )
  }

  const init = async (_config: any) => {
    if (!_config?.streamId && !_config?.streamSourceId) return

    const handleMouseOut = () => {
      widgetInstance?.current?.controls.hideUi()
    }

    const handleMouseOver = () => {
      widgetInstance?.current?.controls.showUi()
    }

    const appendContainer = (containerToAppend: HTMLElement) => {
      sportBuffContainer.current = containerToAppend
      container.current?.appendChild?.(containerToAppend)
    }

    const destroyContainer = () => {
      if (!sportBuffContainer.current) return
      container?.current?.removeChild?.(sportBuffContainer.current)

      container?.current?.removeEventListener('mouseover', handleMouseOver)
      container?.current?.removeEventListener('mouseout', handleMouseOut)
    }

    container?.current?.addEventListener('mouseover', handleMouseOver)
    container?.current?.addEventListener('mouseout', handleMouseOut)

    const widgetConfig = {
      ..._config,
      appendContainer,
      destroyContainer,
      streamSourceId: 'sports360',
      addFullScreenButton: false,
      anonymousLogin,
      fullScreenElement: container.current,
    }

    const config = widgetConfig

    const instance = await window?.SportBuff.init(config)
    widgetInstance.current = instance
  }

  useEffect(() => {
    videoRef?.current?.addEventListener('timeupdate', handleTimeUpdate)
  }, [videoRef.current])

  useEffect(() => {
    const script = document.createElement('script')
    const scriptUrl = ['develop', 'qa', 'sportbuff'].includes(clientName)
      ? `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/latest/bundle-main.min.js`
      : `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/5.x/bundle-main.min.js`

    script.setAttribute('src', scriptUrl)
    script.setAttribute('type', 'text/javascript')

    document.head.appendChild(script)
  }, [])

  useEffect(() => {
    const fetchStreams = async () => {
      const streams = await getStreams(clientName)
      const firstLiveStream = await streams?.find((s) => {
        return streamType === StreamType.VOD
          ? s.endedAt && s.liveAt
          : !s.endedAt && s.liveAt
      })
      setStreamId(firstLiveStream?.id ?? '')
    }

    if (!streamId && !sourceId) fetchStreams()

    let config = {
      player: 'custom-functions',
      clientName,
    }

    if (sourceId && !streamId) {
      config = {
        ...config,
        streamSourceId: sourceId,
      }
    }

    if (!sourceId && streamId) {
      config = {
        ...config,
        streamId,
      }
    }

    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }

      setTimeout(() => {
        init(config)
      }, 200)
    } else {
      ;(window as any).onSportBuffReady = () => {
        init(config)
      }
    }
  }, [streamId, sourceId])

  return (
    <div className={styles.pagebg}>
      <div className={styles.header}>
        <img alt="logo" className={styles.headerLogo} src={logo} />
      </div>
      <div
        id="buff-video-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
        ref={container}
      >
        <Video ref={videoRef} streamType={streamType} videoUrl={videoUrl} />
      </div>
    </div>
  )
}

export default Sports360
