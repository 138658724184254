import { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import ContentList from './contentList'
import './YesNoResults.css'

const YesNoResults = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const [gameId, setGameId] = useState<string | null>(null)
  const queryGameId = query.get('gameId')
  const sourceId = query.get('sourceId')
  const clientName = query.get('clientName') || `sportbuff`
  const [loading, setLoading] = useState<boolean>(true)
  const [percentageDifference, setPercentageDifference] = useState<
    number | null
  >(null)
  const [error, setError] = useState('')
  const headers = {
    accept: 'application/json, text/plain, */*',
    'x-buffup-broadcaster-id': `${clientName}`,
  }

  useEffect(() => {
    const fetchData = async () => {
      if (queryGameId) {
        setGameId(queryGameId)
        setLoading(false)
      } else if (!gameId) {
        if (!sourceId) {
          setError('Missing game_id and source_id')
          return
        }
        // Fetch the gameId from the new API using source_id
        try {
          const filter = `"source_id:${sourceId}"`
          const response = await axios.get(
            `https://api.${clientName}.buffup.net/streams?filter=${encodeURIComponent(
              filter
            )}`,
            { headers }
          )
          const { streams } = response.data
          const [firstStream] = streams
          if (firstStream) {
            const [firstGameId] = firstStream.gameIds
            if (firstGameId) {
              setGameId(firstGameId)
              setLoading(false)
            } else {
              setError('No gameId found in streams response')
              return
            }
          } else {
            setError('No streams found')
            return
          }
        } catch (err) {
          setError('Error fetching gameId')
          return
        }
      }

      if (loading) {
        return <div>Loading...</div>
      }

      if (!gameId) {
        return <div className="error">Failed to load gameId.</div>
      }
    }

    fetchData()
  }, [gameId])

  const fetchDataForVoteableId = async (voteableId: string) => {
    const apiUrl = `https://api.${clientName}.buffup.net/games/${gameId}/voteables/${voteableId}/stats`

    try {
      const response = await axios.get(apiUrl, { headers })
      const { data } = response
      const votes = data.engagement.perAnswer

      if (votes.length !== 2) {
        setError('Unexpected number of answers')
        setPercentageDifference(null)
        return
      }

      const votesCast1 = votes[0].votesCast
      const votesCast2 = votes[1].votesCast
      const totalVotes = votesCast1 + votesCast2

      if (totalVotes === 0) {
        setError('No votes cast')
        setPercentageDifference(null)
        return
      }

      let difference = (Math.abs(votesCast1 - votesCast2) / totalVotes) * 100
      // Round to the nearest even integer
      difference = Math.round(difference / 2) * 2
      setPercentageDifference(difference)
      setError('')
    } catch (err) {
      setError('Error fetching data')
      setPercentageDifference(null)
    }
  }

  return (
    <div className="YesNo">
      <div
        className="ContentList-container"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <ContentList
          gameId={gameId}
          clientName={clientName}
          fetchDataForVoteableId={fetchDataForVoteableId}
        />
      </div>
      <header
        className="YesNo-header"
        style={{ display: 'flex', width: '100%', justifyItems: 'center' }}
      >
        Vote Difference
        {percentageDifference !== null && (
          <div className="result">
            <span>
              {percentageDifference}
              <span className="percentage">%</span>
            </span>
          </div>
        )}
        {error && <div className="error">{error}</div>}
      </header>
    </div>
  )
}

export default YesNoResults
