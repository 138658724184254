import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StreamType } from 'components/Video/Video'
import { getStreams } from 'services/stream'
import mexicoShirt from 'assets/televisa1.png'
import logoSuper6 from 'assets/Logo_Final_Super6Millonario.png'
import logo2 from 'assets/logo.png'

import styles from './Predict6.module.scss'

interface Props {
  logo?: string
  client?: string
}

const Predict6: FC<Props> = ({ logo, client }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [wakeLock, setWakeLock] = useState(null)
  const [streamId, setStreamId] = useState(params?.get('streamId') ?? null)
  const sourceId = params?.get('sourceId')
  const clientName = client ?? params?.get('clientName') ?? 'sportbuff'
  const isPreGameMode = params?.get('pregameMode') ?? false
  const preGameFrequency = params?.get('buffFrequency') ?? 10
  const anonymousLogin = params?.get('anonymousLogin') ?? false
  const token = params?.get('userToken') ?? null
  const widgetInstance = useRef<any>()
  const sportBuffContainer = useRef<HTMLElement>()
  const container = useRef<HTMLDivElement>(null)
  const streamType =
    params?.get('isVOD') === 'true' ? StreamType.VOD : StreamType.LIVE

  const onTabFocus = async () => {
    try {
      if (!wakeLock) {
        // @ts-ignore
        const lockObj = await navigator.wakeLock.request('screen')
        setWakeLock(lockObj as any)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onTabBlur = async () => {
    if (wakeLock) {
      // @ts-ignore
      await wakeLock.release()
      setWakeLock(null)
    }
  }

  const init = async (_config: any) => {
    if (!_config?.streamId && !_config?.streamSourceId) return

    const handleMouseOut = () => {
      widgetInstance?.current?.controls.hideUi()
    }

    const handleMouseOver = () => {
      widgetInstance?.current?.controls.showUi()
    }

    const appendContainer = (containerToAppend: HTMLElement) => {
      sportBuffContainer.current = containerToAppend
      container.current?.appendChild?.(containerToAppend)
    }

    const destroyContainer = () => {
      if (!sportBuffContainer.current) return
      container?.current?.removeChild?.(sportBuffContainer.current)

      container?.current?.removeEventListener('mouseover', handleMouseOver)
      container?.current?.removeEventListener('mouseout', handleMouseOut)
    }

    container?.current?.addEventListener('mouseover', handleMouseOver)
    container?.current?.addEventListener('mouseout', handleMouseOut)

    const widgetConfig = {
      ..._config,
      appendContainer,
      destroyContainer,
    }

    const instance = await window?.SportBuff.init(widgetConfig)
    widgetInstance.current = instance
  }

  useEffect(() => {
    const script = document.createElement('script')

    const scriptUrl = ['develop', 'qa', 'sportbuff'].includes(clientName)
      ? `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/latest/bundle-main.min.js`
      : `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/5.x/bundle-main.min.js`
    script.setAttribute('src', scriptUrl)
    script.setAttribute('type', 'text/javascript')

    document.head.appendChild(script)

    setTimeout(() => {
      const buffContainer = document.getElementById('SportBuff-container')
      if (buffContainer) buffContainer.style.zIndex = '1000'
    }, 2000)

    return () => {
      window.removeEventListener('focus', onTabFocus)
      window.removeEventListener('blur', onTabBlur)
    }
  }, [])

  useEffect(() => {
    const fetchStreams = async () => {
      const streams = await getStreams(clientName)
      const firstLiveStream = await streams?.find((s) => {
        return streamType === StreamType.VOD
          ? s.endedAt && s.liveAt
          : !s.endedAt && s.liveAt
      })
      setStreamId(firstLiveStream?.id ?? '')
    }

    if (!streamId && !sourceId) fetchStreams()

    let config: any = {
      player: 'custom-functions',
      clientName,
    }

    if (token) {
      config = {
        ...config,
        token,
      }
    }

    if (sourceId && !streamId) {
      config = {
        ...config,
        streamSourceId: sourceId,
      }
    }

    if (!sourceId && streamId) {
      config = {
        ...config,
        streamId,
      }
    }

    if (isPreGameMode) {
      config = {
        ...config,
        preStreamBuffFrequency: preGameFrequency,
        anonymousLogin,
      }
    }

    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }

      setTimeout(() => {
        init(config)
      }, 200)
    } else {
      ;(window as any).onSportBuffReady = () => {
        init(config)
      }
    }
  }, [streamId, sourceId])

  function handleWindowSizeChange() {
    // setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <>
      <div
        id="buff-video-container"
        className={styles.container}
        ref={container}
      >
        <header className={styles.header}>
          <div className={styles.logo}>
            <img
              src={logo}
              alt="logo"
              style={{ position: 'relative', top: '18px' }}
            />
          </div>
          <div className={styles.logoSuper6}>
            <img src={logoSuper6} alt="logo" />
          </div>
          <div>
            <img
              src={logo2}
              alt="logo"
              style={{ position: 'absolute', top: '38px', right: '18px' }}
            />
          </div>
        </header>
        <div
          style={{
            display: 'flex',
            height: '343px',
            width: '100%',
            position: 'absolute',
            alignSelf: 'center',
            top: '210px',
            left: '0px',
            zIndex: '100',
          }}
        >
          <h2
            style={{
              color: 'white',
              fontWeight: 700,
              width: '100%',
              textAlign: 'center',
              alignSelf: 'center',
              position: 'relative',
              top: '23px',
              left: '7px',
              zIndex: '1001',
            }}
          >
            WIN A SIGNED
            <br /> MEXICO SHIRT
          </h2>
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: '0px',
              left: '0px',
              backgroundImage: `url(${mexicoShirt})`,
              maskImage:
                ' linear-gradient(180deg, white 44%, rgba(115, 115, 115, 0) 100%)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          />
        </div>
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.2em',
          }}
        /> */}
        {/* <div className={styles.footer}>
          <div
            className={styles.bgBox}
            style={{
              backgroundImage: `url(${boxBg})`,
            }}
          />
        </div> */}
      </div>
    </>
  )
}

export default Predict6

Predict6.defaultProps = {
  logo: undefined,
  client: undefined,
}
