import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Home } from 'screens/Home'
import { Dailymotion } from 'screens/Dailymotion'
import { LandingPage } from 'screens/LandingPage'
import { Predict6 } from 'screens/Predict6'
import { YesNoResults, YesNo } from 'screens/YesNo'
import euroleagueLogo from 'assets/euroleague.png'
import tv2Logo from 'assets/tv2Logo.png'
import tv2Bg from 'assets/tv2Bg.jpg'
import ligamx from 'assets/ligamx.png'
import sfxBg from 'assets/SFX_Background.jpg'
import yesNo from 'assets/yesno.png'
import { Jwplayer } from 'screens/Jwplayer'
import { Sports360 } from 'screens/Sports360'

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/euroleague">
        <LandingPage logo={euroleagueLogo} isPreStream />
      </Route>
      <Route exact path="/tv2">
        <LandingPage
          client="tv-2"
          logo={tv2Logo}
          bgImage={tv2Bg}
          isPreStream={false}
        />
      </Route>
      <Route exact path="/yesNo">
        <YesNo client="sportbuff" logo={yesNo} anonymousLogin />
      </Route>
      <Route exact path="/YesNoResults">
        <YesNoResults />
      </Route>
      <Route exact path="/sfx">
        <LandingPage client="sportbuff" bgImage={sfxBg} isPreStream />
      </Route>
      <Route exact path="/predict6">
        <Predict6 logo={ligamx} />
      </Route>
      <Route exact path="/televisa">
        <Predict6 logo={ligamx} />
      </Route>
      <Route exact path="/pregame">
        <LandingPage isPreStream />
      </Route>
      <Route exact path="/playlist">
        <Home isPlaylistMode />
      </Route>
      <Route exact path="/tv">
        <Home isTvMode />
      </Route>
      <Route exact path="/portrait">
        <Home fixedPortraitBuffs />
      </Route>
      <Route exact path="/tvsync">
        <Home isTvMode isTimeSync />
      </Route>
      <Route exact path="/dailymotion">
        <Dailymotion />
      </Route>
      <Route exact path="/jwplayer">
        <Jwplayer />
      </Route>
      <Route exact path="/360sports">
        <Sports360 />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  </Router>
)

export default Routes
