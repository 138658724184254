import { FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StreamType } from 'components/Video/Video'
import { getStreams } from 'services/stream'
import NoSleep from 'utils/noSleep'
import styles from './YesNo.module.scss'

interface Props {
  logo?: string
  client?: string
  anonymousLogin?: boolean
}

const noSleep = new NoSleep()

const YesNo: FC<Props> = ({ logo, client, anonymousLogin }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [width, setWidth] = useState(window.innerWidth)
  const [wakeLock, setWakeLock] = useState(null)
  const [streamId, setStreamId] = useState(params?.get('streamId') ?? null)
  const sourceId = params?.get('sourceId')
  const clientName = client ?? params?.get('clientName') ?? 'qa'
  const widgetInstance = useRef<any>()
  const sportBuffContainer = useRef<HTMLElement>()
  const container = useRef<HTMLDivElement>(null)
  const streamType =
    params?.get('isVOD') === 'true' ? StreamType.VOD : StreamType.LIVE

  const onTabFocus = async () => {
    try {
      if (!wakeLock) {
        // @ts-ignore
        const lockObj = await navigator.wakeLock.request('screen')
        setWakeLock(lockObj as any)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onTabBlur = async () => {
    if (wakeLock) {
      // @ts-ignore
      await wakeLock.release()
      setWakeLock(null)
    }
  }

  const init = async (_config: any) => {
    if (!_config?.streamId && !_config?.streamSourceId) return

    const handleMouseOut = () => {
      widgetInstance?.current?.controls.hideUi()
    }

    const handleMouseOver = () => {
      widgetInstance?.current?.controls.showUi()
    }

    const appendContainer = (containerToAppend: HTMLElement) => {
      sportBuffContainer.current = containerToAppend
      container.current?.appendChild?.(containerToAppend)
    }

    const destroyContainer = () => {
      if (!sportBuffContainer.current) return
      container?.current?.removeChild?.(sportBuffContainer.current)

      container?.current?.removeEventListener('mouseover', handleMouseOver)
      container?.current?.removeEventListener('mouseout', handleMouseOut)
    }

    container?.current?.addEventListener('mouseover', handleMouseOver)
    container?.current?.addEventListener('mouseout', handleMouseOut)

    const widgetConfig = {
      ..._config,
      streamId: null,
      streamSourceId: 'yesno',
      anonymousLogin: { anonymousLogin },
      appendContainer,
      destroyContainer,
    }

    const instance = await window?.SportBuff.init(widgetConfig)
    widgetInstance.current = instance
  }

  useEffect(() => {
    const script = document.createElement('script')

    const scriptUrl = ['develop', 'qa', 'sportbuff'].includes(clientName)
      ? `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/latest/bundle-main.min.js`
      : `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/5.x/bundle-main.min.js`
    script.setAttribute('src', scriptUrl)
    script.setAttribute('type', 'text/javascript')

    document.head.appendChild(script)

    setTimeout(() => {
      const buffContainer = document.getElementById('SportBuff-container')
      if (buffContainer) buffContainer.style.zIndex = '1000'
    }, 2000)

    document.addEventListener(
      'click',
      () => {
        noSleep.enable()
      },
      { once: true }
    )

    return () => {
      window.removeEventListener('focus', onTabFocus)
      window.removeEventListener('blur', onTabBlur)
    }
  }, [])

  useEffect(() => {
    const fetchStreams = async () => {
      const streams = await getStreams(clientName)
      const firstLiveStream = await streams?.find((s) => {
        return streamType === StreamType.VOD
          ? s.endedAt && s.liveAt
          : !s.endedAt && s.liveAt
      })
      setStreamId(firstLiveStream?.id ?? '')
    }

    if (!streamId && !sourceId) fetchStreams()

    let config: any = {
      player: 'custom-functions',
      clientName,
    }

    if (sourceId && !streamId) {
      config = {
        ...config,
        streamSourceId: sourceId,
      }
    }

    if (!sourceId && streamId) {
      config = {
        ...config,
        streamId,
      }
    }

    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }

      setTimeout(() => {
        init(config)
      }, 200)
    } else {
      ;(window as any).onSportBuffReady = () => {
        init(config)
      }
    }
  }, [streamId, sourceId])

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <>
      <div
        id="buff-video-container"
        className={styles.container}
        ref={container}
      >
        {logo && (
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
        )}
      </div>
    </>
  )
}

export default YesNo

YesNo.defaultProps = {
  logo: undefined,
  anonymousLogin: undefined,
  client: undefined,
}
