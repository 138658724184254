import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './contentList.css'

interface ContentListProps {
  gameId: string | null
  clientName: string
  fetchDataForVoteableId: (parameter: string) => void
}

interface Localisation {
  text: string
}

interface Question {
  localisations: Localisation[]
}

interface Entity {
  voteableId: string
  question: Question
}

interface ContentItem {
  entity: Entity
  timestamp: string
  type: string
}

const ContentList: React.FC<ContentListProps> = ({
  gameId,
  clientName,
  fetchDataForVoteableId,
}) => {
  const [content, setContent] = useState<ContentItem[]>([])
  const [showSidebar, setShowSidebar] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    let isMounted = true // Track if the component is mounted
    const fetchData = async () => {
      if (!gameId) {
        return
      }
      try {
        const response = await axios.get(
          `https://api.${clientName}.buffup.net/games/${gameId}/content?pagination.pageSize=100`,
          {
            headers: {
              accept: '*/*',
              'x-buffup-broadcaster-id': `${clientName}`,
            },
          }
        )
        if (isMounted) {
          setContent(response.data.content)
        }
      } catch (err) {
        if (isMounted) {
          setError('Error fetching content')
        }
      }
    }

    fetchData()
    return () => {
      isMounted = false // Cleanup function to set isMounted to false
    }
  }, [gameId, clientName])

  const filteredContent = content.filter(
    (item: any) => item.type !== 'ANNOUNCEMENT'
  )

  return (
    <div className="ContentList">
      {showSidebar ? (
        <div className="ContentList-show">
          <header className="ContentList-header">
            <div>
              <h1>Buffs</h1>
              {error && <div className="error">{error}</div>}
            </div>
            <div>
              <button
                type="button"
                className="blue-button"
                onClick={() => setShowSidebar(false)}
              >
                {'<'}
              </button>
            </div>
          </header>
          <ul>
            {filteredContent.map((item) => (
              <li key={item.entity.voteableId} className="content-item">
                <h2>{item.entity.question.localisations[0].text}</h2>
                <br />
                <p className="ContentList-time">
                  Time: {new Date(item.timestamp).toLocaleString()}
                </p>
                <button
                  type="button"
                  className="blue-button"
                  onClick={() => fetchDataForVoteableId(item.entity.voteableId)}
                >
                  Show Results
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <div className="ContentList-hide">
            <button type="button" onClick={() => setShowSidebar(true)}>
              {'>'}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default ContentList
