import React, { useEffect, useState, useRef, FC } from 'react'
import ReactJWPlayer from 'react-jw-player'
import { StreamType } from 'components/Video/Video'
import { useLocation } from 'react-router-dom'
import { Header } from 'components/Header'
import { BuffInfo } from 'components/BuffInfo'
import { getStreams } from 'services/stream'
import styles from './Jwplayer.module.scss'

interface Props {
  isPlaylistMode?: boolean
  isTvMode?: boolean
  isTimeSync?: boolean
}

const Jwplayer: FC<Props> = ({ isPlaylistMode, isTvMode, isTimeSync }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [streamId, setStreamId] = useState(params?.get('streamId') ?? null)
  const [playerInstance, setPlayerInstance] = useState()
  const clientName = params?.get('clientName') ?? 'qa'
  const delay = params?.get('delay') ?? '10'
  const isPreStream = params?.get('preStream') ?? 'true'
  const frequency = params?.get('frequency') ?? '10'
  const tvDeviceId = params?.get('tvDeviceId')
  const widgetInstance = useRef<any>()
  const sportBuffContainer = useRef<HTMLElement>()
  const container = useRef<HTMLDivElement>(null)
  const videoUrl = `https://buffup-public.prod.buffup.net/video/${
    params?.get('videoId') ?? 'FIFA_VOD_SHORT'
  }.${params?.get('videoExt') ?? 'mp4'}`
  const streamType =
    params?.get('isVOD') === 'true' ? StreamType.VOD : StreamType.LIVE

  const jwPlayerId = 'my-unique-id'

  const init = async (_config: any) => {
    if (!_config?.streamId || !playerInstance) return

    const handleMouseOut = () => {
      widgetInstance?.current?.controls.hideUi()
    }

    const handleMouseOver = () => {
      widgetInstance?.current?.controls.showUi()
    }

    const videoContainer = (playerInstance as any).getContainer()

    const appendContainer = (containerToAppend: HTMLElement) => {
      sportBuffContainer.current = containerToAppend
      videoContainer.appendChild?.(containerToAppend)
    }

    const destroyContainer = () => {
      if (!sportBuffContainer.current) return
      videoContainer.removeChild?.(sportBuffContainer.current)

      videoContainer.removeEventListener('mouseover', handleMouseOver)
      videoContainer.removeEventListener('mouseout', handleMouseOut)
    }

    videoContainer.addEventListener('mouseover', handleMouseOver)
    videoContainer.addEventListener('mouseout', handleMouseOut)

    const widgetConfig = {
      ..._config,
      appendContainer,
      destroyContainer,
    }

    const widgetConfigPlaylist = {
      ..._config,
      appendContainer,
      destroyContainer,
    }

    const widgetConfigTV = {
      ..._config,
      appendContainer,
      destroyContainer,
      tvDeviceId,
      qrCodeVisibleDuration: 10,
      qrCodeVisibleFrequency: 1,
    }

    let config = widgetConfig

    if (isPlaylistMode) {
      config = widgetConfigPlaylist
    }

    if (isTvMode) {
      config = widgetConfigTV
    }

    if (isPreStream === 'true') {
      config = {
        ...config,
        preStreamBuffFrequency: parseInt(frequency, 10),
      }
    }

    const instance = await window?.SportBuff.init(config)
    widgetInstance.current = instance

    if (isTimeSync) {
      setInterval(() => {
        const date = new Date()
        date.setSeconds(date.getSeconds() - parseInt(delay, 10))
        instance.controls.updateTimeTimeSync(date.getTime())
      }, 1000)
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    const scriptUrl = ['develop', 'qa', 'sportbuff'].includes(clientName)
      ? `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/latest/bundle-main.min.js`
      : `https://buffup-web-sdk.core.buffup.net/buffup-web-sdk/${clientName}/5.x/bundle-main.min.js`

    script.setAttribute('src', scriptUrl)
    script.setAttribute('type', 'text/javascript')

    document.head.appendChild(script)
  }, [])

  useEffect(() => {
    if (!playerInstance) return

    const fetchStream = async () => {
      const streams = await getStreams(clientName)
      const firstLiveStream = await streams?.find((s) => {
        return streamType === StreamType.VOD
          ? s.endedAt && s.liveAt
          : !s.endedAt && s.liveAt
      })
      setStreamId(firstLiveStream?.id ?? '')
    }

    if (!streamId) fetchStream()

    const config = {
      player: 'custom-functions',
      streamId,
      clientName,
    }

    if (window.SportBuff) {
      if (widgetInstance.current) {
        widgetInstance.current.destroy()
      }

      setTimeout(() => {
        init(config)
      }, 200)
    } else {
      ;(window as any).onSportBuffReady = () => {
        init(config)
      }
    }
  }, [streamId, playerInstance])

  const onJWPlayerReady = () => {
    const player = (window as any).jwplayer(jwPlayerId)
    setPlayerInstance(player)
  }

  const onJWPFullScreen = (e: any) => {
    if (e.fullscreen) {
      const config = {
        player: 'custom-functions',
        streamId,
        clientName,
      }

      widgetInstance.current.destroy()
      init(config)
    }
  }

  return (
    <>
      <Header />
      <div className={styles.content}>
        <section className={styles.section}>
          <div className={styles.leftSide}>
            <BuffInfo />
          </div>

          <div className={styles.rightSide}>
            <div
              ref={container}
              id="buff-video-container"
              style={{ position: 'relative', width: '100%' }}
            >
              <ReactJWPlayer
                playerId="my-unique-id"
                playerScript="https://content.jwplatform.com/libraries/j9BLvpMc.js"
                file={videoUrl}
                onReady={onJWPlayerReady}
                onEnterFullScreen={onJWPFullScreen}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Jwplayer

Jwplayer.defaultProps = {
  isPlaylistMode: false,
  isTvMode: false,
  isTimeSync: false,
}
